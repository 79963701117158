import {
  RequestCookies,
  ResponseCookies,
} from 'next/dist/compiled/@edge-runtime/cookies';
import { type NextRequest, NextResponse } from 'next/server';

import { STORE_STORAGE_PREFIX } from 'shared/constants/FeatureFlags';
import { getSumupCookieDomain } from 'utils/cookies';

import { EXPERIMENT_USER_COOKIE_NAME } from './browser-user';
import type { Experiment } from './decision-flags';

const FLAG_COOKIE_PREFIX = `${STORE_STORAGE_PREFIX}flag-`;
const EXPERIMENT_COOKIE_PREFIX = `${STORE_STORAGE_PREFIX}experiment-`;

export const getFlagCookieKey = (key: string): string =>
  `${FLAG_COOKIE_PREFIX}${key}`;

export const getExperimentCookieKey = (key: string): string =>
  `${EXPERIMENT_COOKIE_PREFIX}${key}`;

const cleanupCookieByPrefixInResponse = (
  prefix: string,
  reqCookies: RequestCookies,
  resCookies: ResponseCookies,
  skip: string[] = [],
): void => {
  const domain = getSumupCookieDomain();

  reqCookies
    .getAll()
    .filter(({ name }) => name.startsWith(prefix) && !skip.includes(name))
    .forEach(({ name }) => resCookies.delete({ name, domain }));
};

export const cleanupExperimentCookiesInResponse = (
  experiments: Experiment[],
  reqCookies: RequestCookies,
  resCookies: ResponseCookies,
): void => {
  const experimentCookieKeys = experiments.map(({ experimentKey }) =>
    getExperimentCookieKey(experimentKey),
  );

  cleanupCookieByPrefixInResponse(
    EXPERIMENT_COOKIE_PREFIX,
    reqCookies,
    resCookies,
    experimentCookieKeys,
  );
};

export const cleanupFlagCookiesInResponse = (
  flags: string[],
  reqCookies: RequestCookies,
  resCookies: ResponseCookies,
): void => {
  const flagCookieKeys = flags.map(getFlagCookieKey);

  cleanupCookieByPrefixInResponse(
    FLAG_COOKIE_PREFIX,
    reqCookies,
    resCookies,
    flagCookieKeys,
  );
};

const setCookieInResponse = (
  response: NextResponse,
  key: string,
  value: string,
  options: Record<string, unknown> = {},
): ResponseCookies =>
  response.cookies.set(key, value, {
    domain: getSumupCookieDomain(),
    ...options,
  });

export const setFlagCookieInResponse = (
  response: NextResponse,
  key: string,
  value: string,
): ResponseCookies =>
  setCookieInResponse(response, getFlagCookieKey(key), value);

export const setExperimentCookieInResponse = (
  response: NextResponse,
  key: string,
  value: string,
): ResponseCookies =>
  setCookieInResponse(response, getExperimentCookieKey(key), value);

export const setUserIdCookieInResponse = (
  response: NextResponse,
  userId: string,
): void => {
  const today = new Date();
  const oneYearFromNow = new Date(today.setFullYear(today.getFullYear() + 1));

  setCookieInResponse(response, EXPERIMENT_USER_COOKIE_NAME, userId, {
    expires: oneYearFromNow,
  });
};

/**
 * Copy cookies from the Set-Cookie header of the response to the Cookie header of the request,
 * so that it will appear to SSR/RSC as if the user already has the new cookies.
 * https://github.com/vercel/next.js/discussions/50374
 */
export const applySetCookie = (req: NextRequest, res: NextResponse): void => {
  const setCookies = new ResponseCookies(res.headers);

  const newReqHeaders = new Headers(req.headers);
  const newReqCookies = new RequestCookies(newReqHeaders);

  setCookies.getAll().forEach((cookie) => newReqCookies.set(cookie));

  const dummyRes = NextResponse.next({ request: { headers: newReqHeaders } });

  dummyRes.headers.forEach((value, key) => {
    if (
      key === 'x-middleware-override-headers' ||
      key.startsWith('x-middleware-request-')
    ) {
      res.headers.set(key, value);
    }
  });
};
