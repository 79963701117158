import { withOrderInfo } from 'shared/hocs/withOrderInfo';

import {
  OrderOverview,
  type Props,
  type OverviewContent,
} from './OrderOverview';

export type { Props as OrderOverviewProps, OverviewContent };

const OrderOverviewContainer = withOrderInfo<Props>(OrderOverview);

export { ConditionsCard } from './components/ConditionsCard/ConditionsCard';
export { DueMonthlyCard } from './components/DueMonthlyCard/DueMonthlyCard';
export { DueNowCard } from './components/DueNowCard/DueNowCard';
export { OrderOverview, OrderOverviewContainer };
