import { useRouter } from 'next/router';
import type { FC } from 'react';

import type {
  OrderOverviewProps,
  OverviewContent,
} from 'shared/components/OrderOverview';
import { getDueNowProducts } from 'shared/services/ProductService';
import type { AddressPayload } from 'shared/store/address/types';
import type { OrderDetails as IOrderDetails } from 'shared/store/order/types';
import { getOrderTotalAmountFormatted } from 'utils/tax';

import {
  OverviewWrapper,
  StyledHeadline,
  StyledHr,
} from '../../OrderOverview.styles';
import type { OverviewProps } from '../../OrderOverviewService';
import { BusinessAccountRow } from '../BusinessAccountRow/BusinessAccountRow';
import { DeliveryRow } from '../DeliveryRow/DeliveryRow';
import { DiscountRow } from '../DiscountRow/DiscountRow';
import { SubtotalRow } from '../SubtotalRow/SubtotalRow';
import { TotalAmountRow } from '../TotalAmountRow/TotalAmountRow';
import { VatRateRow } from '../VatRateRow/VatRateRow';

type OrderDetails = Pick<
  IOrderDetails,
  | 'discountAmountCents'
  | 'formattedDiscountAmount'
  | 'formattedSubtotalAmount'
  | 'formattedTotalAmountWithTaxes'
  | 'formattedTotalTaxAmount'
  | 'taxRate'
>;

type DueNowContent = Pick<
  OverviewContent,
  | 'businessAccountCostsLabel'
  | 'businessAccountDescription'
  | 'deliveryEstimateText'
  | 'discountAppliedText'
  | 'dueNowTitleText'
  | 'freeText'
  | 'subtotalText'
  | 'taxRateText'
>;

export interface Props extends OverviewProps {
  content: DueNowContent;
  defaultTaxRate: number;
  hasBusinessAccount: boolean;
  orderDetails: OrderDetails;
  products: OrderOverviewProps['products'];
  shippingAddress: AddressPayload;
  totalAmountFloat?: number;
}

export const DueNowCard: FC<Props> = (props) => {
  const {
    content,
    defaultTaxRate,
    hasBusinessAccount,
    noMargin,
    orderDetails,
    products,
    shippingAddress,
    totalAmountFloat,
    usedForCart,
    usedInModal,
  } = props;

  const { locale } = useRouter();
  const dueNowProducts = getDueNowProducts(products);

  if (dueNowProducts.length === 0) {
    return null;
  }

  const productTypes = dueNowProducts.map((p) => p.productType);

  const totalAmountFormatted = getOrderTotalAmountFormatted({
    shippingAddress,
    defaultTaxRate,
    totalAmountFloat,
    formattedTotalAmountWithTaxes: orderDetails.formattedTotalAmountWithTaxes,
    locale,
    doFallbackToDefaultTaxRate: true,
  });

  return (
    <OverviewWrapper noMargin={noMargin} usedInModal={usedInModal}>
      <StyledHeadline size="three" as="h2">
        {content.dueNowTitleText}
      </StyledHeadline>

      <SubtotalRow
        amountFormatted={orderDetails.formattedSubtotalAmount}
        productTypes={productTypes}
        text={content.subtotalText}
        usedForCart={usedForCart}
      />

      <DeliveryRow
        deliveryEstimateText={content.deliveryEstimateText}
        freeText={content.freeText}
        usedForCart={usedForCart}
      />

      <BusinessAccountRow
        businessAccountCostsLabel={content.businessAccountCostsLabel}
        businessAccountDescription={content.businessAccountDescription}
        hasBusinessAccount={hasBusinessAccount}
        usedForCart={usedForCart}
      />

      {orderDetails.discountAmountCents !== 0 && (
        <DiscountRow
          amountFormatted={orderDetails.formattedDiscountAmount}
          appliedText={content.discountAppliedText}
          usedForCart={usedForCart}
        />
      )}

      <VatRateRow
        defaultTaxRate={defaultTaxRate}
        formattedTotalTaxAmount={orderDetails.formattedTotalTaxAmount}
        locale={locale}
        shippingAddress={shippingAddress}
        taxRate={orderDetails.taxRate}
        taxRateText={content.taxRateText}
        totalAmountFloat={totalAmountFloat}
        usedForCart={usedForCart}
      />

      <StyledHr />

      <TotalAmountRow
        amount={totalAmountFormatted}
        title={content.dueNowTitleText}
        usedForCart={usedForCart}
      />
    </OverviewWrapper>
  );
};
