import { useRouter } from 'next/router';
import { useEffect, useState, type FC } from 'react';

import type { Product, UpsellProduct } from 'productSelection/types/products';
import { getSubscription } from 'shared/infra/storefront/orders';
import logger from 'shared/services/logger';
import type { PaymentPlan } from 'shared/services/PaymentPlanService';
import {
  combineProductsInfo,
  hasOrderSubscriptionInCart,
  type Subscription,
} from 'shared/services/ProductService';
import { useTypedSelector } from 'shared/store';
import type { OrderDetails, OrderLineItem } from 'shared/store/order/types';
import { getChannelLink } from 'shared/utils/channel-link';
import { isSumUpOneInProductList } from 'src/cart/services/CartOverviewService';
import type { Maybe, Subtract } from 'types/util';

// External Props
// withOrderInfo use these props to decide injected props
interface ExternalProps {
  productsContentful: Product[];
  upsellProductsContentful: UpsellProduct[];
  largestInstallmentInCart?: number;
}

// Injected Props
export interface InjectedOrderInfoProps extends OrderDetails {
  products: Product[];
  productsContentful: Product[];
  upsellProductsContentful: UpsellProduct[];
  outOfCatalogLineItems: OrderLineItem[];
  paymentPlan: Maybe<PaymentPlan>;
  loading: boolean;
  largestInstallmentInCart: number;
  hasBusinessAccount: boolean;
  hasSumUpOne: boolean;
}

type WithoutInjectedProps<T extends Partial<InjectedOrderInfoProps>> = Subtract<
  T,
  Partial<InjectedOrderInfoProps>
>;

// https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb injector pattern
// This removes the injected prop from the prop types of the wrapped component,
// which allows us to require the prop (in this case 'products')
// inside the wrapped component, while also not having to provide them
// in the page/component that is importing the wrapped component
// to keep TypeScript from breaking
// see OrderSummary (wrapped comp) used in ThankYou page as example
export function withOrderInfo<T extends Partial<InjectedOrderInfoProps>>(
  Comp: FC<T>,
): FC<WithoutInjectedProps<T> & ExternalProps> {
  const displayName = Comp.displayName || Comp.name || 'Component';

  const WrappedComponent: FC<WithoutInjectedProps<T> & ExternalProps> = ({
    largestInstallmentInCart,
    productsContentful,
    upsellProductsContentful,
    ...props
  }) => {
    const {
      orderDetails,
      products: productsInCart,
      loading,
    } = useTypedSelector((state) => state.order);
    const { query, replace } = useRouter();

    const [subscriptionDetails, setSubscriptionDetails] =
      useState<Subscription | null>(null);

    useEffect(() => {
      if (!orderDetails.id) {
        return;
      }

      const fetchSubscription = async (): Promise<void> => {
        if (!hasOrderSubscriptionInCart(productsInCart, productsContentful)) {
          return;
        }

        try {
          const subscription = await getSubscription(orderDetails.id);
          setSubscriptionDetails(subscription);
        } catch (err) {
          logger.error(err, 'Could not get subscription details');

          void replace({
            pathname: getChannelLink('/checkout/failure'),
            query,
          });
        }
      };

      void fetchSubscription();
    }, [orderDetails.id, productsContentful, productsInCart, query, replace]);

    const { products, outOfCatalogLineItems } = combineProductsInfo(
      productsContentful,
      upsellProductsContentful,
      productsInCart,
      subscriptionDetails,
    );

    // Get installments for current order
    const productSKUs = productsInCart.map((p) => p.code);
    const productInstallments = productsContentful
      .filter((p) => productSKUs.includes(p.code))
      .map((p) => p.numberOfInstallments ?? 1);

    const hasBusinessAccount = !!productsInCart.find(
      (product) =>
        !!products.find((p) => p?.businessAccount?.code === product.code),
    );

    const hasSumUpOne = isSumUpOneInProductList(productsInCart);

    const largestInstallment =
      largestInstallmentInCart || Math.max(...productInstallments);

    return (
      <Comp
        {...(props as unknown as T)}
        {...orderDetails}
        products={products}
        productsContentful={productsContentful}
        upsellProductsContentful={upsellProductsContentful}
        outOfCatalogLineItems={outOfCatalogLineItems}
        loading={loading}
        largestInstallmentInCart={largestInstallment}
        hasBusinessAccount={hasBusinessAccount}
        hasSumUpOne={hasSumUpOne}
      />
    );
  };

  WrappedComponent.displayName = `withOrderInfo_${displayName}`;

  return WrappedComponent;
}
