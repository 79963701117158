import { useRouter } from 'next/router';
import type { FC } from 'react';

import type { OrderOverviewProps } from 'shared/components/OrderOverview';
import * as TestIds from 'shared/constants/TestIds';
import type { IUiCartFields } from 'shared/infra/contentful/contentful';
import {
  getLicenseProductType,
  isSubscriptionProduct,
} from 'shared/services/ProductService';
import { formatCurrencyWithLocale } from 'utils/currency';

import {
  OverviewWrapper,
  StyledHeadline,
  StyledHr,
} from '../../OrderOverview.styles';
import type { OverviewProps } from '../../OrderOverviewService';
import { DiscountRow } from '../DiscountRow/DiscountRow';
import { SubtotalRow } from '../SubtotalRow/SubtotalRow';
import { TotalAmountRow } from '../TotalAmountRow/TotalAmountRow';
import { VatRateRow } from '../VatRateRow/VatRateRow';

export type DueMonthlyContent = Pick<
  IUiCartFields,
  | 'discountAppliedText'
  | 'dueMonthlyTitleText'
  | 'dueMonthlyTotalText'
  | 'dueMonthlyPaymentStartLabel'
  | 'subtotalText'
  | 'taxRateText'
>;

export interface Props extends OverviewProps {
  content: DueMonthlyContent;
  products: OrderOverviewProps['products'];
}

export const DueMonthlyCard: FC<Props> = (props) => {
  const { content, noMargin, products, usedForCart, usedInModal } = props;
  const { locale } = useRouter();

  const { subscription } = products.find(isSubscriptionProduct) ?? {};

  if (!subscription) {
    return null;
  }

  const subtotalAmountFormatted = formatCurrencyWithLocale(
    subscription.total_amount_float,
    locale,
  );

  const licenseProductType = getLicenseProductType(products);

  const discountAmountFormatted = subscription.discount_float
    ? formatCurrencyWithLocale(subscription.discount_float, locale)
    : '';

  const totalTaxAmountFormatted = formatCurrencyWithLocale(
    subscription.tax_amount_float,
    locale,
  );

  const totalAmountFormatted = formatCurrencyWithLocale(
    subscription.total_amount_with_taxes_and_discounts_float,
    locale,
  );

  return (
    <OverviewWrapper
      css={{ marginBottom: 'var(--cui-spacings-giga)' }}
      data-testid={TestIds.OrderOverview.ContainerWrapper}
      noMargin={noMargin}
      usedInModal={usedInModal}
    >
      <StyledHeadline size="three" as="h2">
        {content.dueMonthlyTitleText}
      </StyledHeadline>

      <SubtotalRow
        amountFormatted={subtotalAmountFormatted}
        productTypes={[licenseProductType]}
        text={content.subtotalText}
        usedForCart={usedForCart}
      />

      {discountAmountFormatted && (
        <DiscountRow
          appliedText={content.discountAppliedText}
          amountFormatted={discountAmountFormatted}
          usedForCart={usedForCart}
        />
      )}

      <VatRateRow
        formattedTotalTaxAmount={totalTaxAmountFormatted}
        taxRate={subscription.tax_rate}
        taxRateText={content.taxRateText}
        usedForCart={usedForCart}
      />

      <StyledHr />

      <TotalAmountRow
        amount={totalAmountFormatted}
        paymentStartLabel={content.dueMonthlyPaymentStartLabel}
        title={content.dueMonthlyTotalText}
        usedForCart={usedForCart}
      />
    </OverviewWrapper>
  );
};
